import { Accordion } from "@/components/ui-components/Accordion";
import { FrequentlyAskedQuestions } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import React from "react";

// import { Accordion } from "flowbite-react";

export const SectionFrequentlyAskedQuestions = ({
  className,
  data,
  dataDefault,
  classBgColor,
  classBorderColor,
  classTextColor,
  classStrokeColor,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  data?: FrequentlyAskedQuestions | undefined;
  dataDefault?: FrequentlyAskedQuestions | undefined;
  classBgColor: string | undefined;
  classBorderColor: string | undefined;
  classTextColor: string | undefined;
  classStrokeColor: string | undefined;
}) => {
  return (
    <div className={classNames(classBgColor, className ?? "")} {...rest}>
      {data && (
        <div className="flex w-full flex-col items-center justify-center p-5 lg:container sm:p-10 lg:mx-auto">
          <h1
            className={classNames(
              "w-full max-w-[250px] rounded-2xl py-4 text-center text-[28px] font-medium sm:max-w-none sm:text-5xl",
              classTextColor
            )}>
            <Trans i18nKey={data?.title ?? dataDefault?.title ?? ""} />
          </h1>
          <p
            className={classNames(
              "mb-10 w-full rounded-2xl text-center text-lg font-light sm:py-4",
              classTextColor
            )}>
            <Trans i18nKey={data?.description ?? dataDefault?.description ?? ""} />
          </p>

          {data.items.map((item, index) => (
            <Accordion
              key={index}
              title={item.title}
              className={classNames(
                `w-full border ${classBorderColor} border-x-0 ${index != data?.items.length - 1 && " border-b-0"}`
              )}
              classTextColor={classTextColor}
              classStrokeColor={classStrokeColor}>
              <p
                className={classNames("py-4 text-left text-lg font-light group-hover:block", classTextColor)}>
                <Trans i18nKey={item.description} />
              </p>
            </Accordion>
          ))}
        </div>
      )}
    </div>
  );
};
