import { Footer } from "@/lib/types";
import classNames from "classnames";
import { Trans } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import { FaXTwitter, FaFacebookF, FaInstagram } from "react-icons/fa6";

function AppFooter({
  footer,
  className,
  ...rest
}: React.HTMLAttributes<HTMLDivElement> & {
  footer: Footer | undefined;
}) {
  return (
    <footer
      className={classNames("sticky top-[100vh] w-full bg-brand-bg-footer ", className ?? "")}
      {...rest}>
      <div className="container">
        <div className="grid grid-cols-2 px-5 py-10 sm:px-10 lg:grid-cols-11  lg:px-28 lg:pl-16 lg:pr-14 ">
          <div className="col-span-2 mb-10 pr-2 lg:col-span-5 lg:mb-0">
            <img
              className=""
              src={footer?.logo || "/images/logo-white.svg"}
              alt="Simple Therapy"
              width="245"
              height="36"
            />
            <p className="mt-6 text-lg font-medium text-brand-text-white">
              <Trans i18nKey={footer?.followUsOnSocialMedia ?? "follow_us_on_social_media"} />
            </p>
            <div className="flex flex-row">
              <Link
                href={`${footer?.instagramLink}`}
                target="_blank"
                className="mr-6 text-xl font-semibold text-brand-text-blue-light text-white transition-colors duration-300 hover:text-brand-text-blue-light">
                <FaInstagram />
              </Link>
              <Link
                href={`${footer?.twitter}`}
                target="_blank"
                className="mr-6 text-xl font-semibold text-brand-text-blue-light text-white transition-colors duration-300 hover:text-brand-text-blue-light">
                <FaXTwitter />
              </Link>
              <Link
                href={`${footer?.facebook}`}
                target="_blank"
                className="text-xl font-semibold text-brand-text-blue-light text-white transition-colors duration-300 hover:text-brand-text-blue-light">
                <FaFacebookF />
              </Link>
            </div>
          </div>

          <div className="border-r-1 col-span-2 border border-b-0 border-l-0 border-t-0 border-transparent pr-2 md:col-span-1 md:border-white lg:col-span-3">
            <h3 className="mb-2 text-lg font-medium text-brand-text-white">
              <Trans i18nKey={footer?.column1Title ?? "footer_column_1_title"} />
            </h3>
            <p className="mb-5 text-base font-light text-brand-text-white">
              <Trans i18nKey={footer?.column1Description ?? "footer_column_1_description"} />
            </p>
            {footer?.column1phone1 && footer?.column1phone1 != "" && (
              <div className="flex flex-wrap gap-2">
                <img src="/images/icons/call.svg" alt="call" />
                <p className="text-base font-light text-brand-text-white">
                  <Trans i18nKey={footer?.column1phone1Title ?? "footer_column_1_phone_1"} />
                </p>
                <a
                  className=" relative text-base font-light text-brand-text-white after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                  href={`tel:${footer?.column1phone2}`}>
                  {footer?.column1phone1}
                </a>
              </div>
            )}
            {footer?.column1phone2 && footer?.column1phone2 != "" && (
              <div className="flex flex-wrap gap-2">
                <img src="/images/icons/call.svg" alt="call" />
                <p className="flex text-base font-light text-white">
                  <Trans i18nKey={footer?.column1phone2Title ?? "footer_column_1_phone_2"} />
                </p>
                <a
                  className="relative text-base font-light text-white after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                  href={`tel:${footer?.column1phone2}`}>
                  {footer?.column1phone2}
                </a>
              </div>
            )}
            {footer?.column1phone3 && footer?.column1phone3 != "" && (
              <div className="flex flex-wrap gap-2">
                <img src="/images/icons/call.svg" alt="call" />
                <p className="flex text-base font-light text-white">
                  <Trans i18nKey={footer?.column1phone3Title ?? "footer_column_1_phone_3"} />
                </p>
                <a
                  className="relative text-base font-light text-white after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                  href={`tel:${footer?.column1phone3}`}>
                  {footer?.column1phone3}
                </a>
              </div>
            )}
          </div>

          <div className="col-span-2 mt-10 md:col-span-1 md:mt-0 md:pl-4 lg:col-span-3">
            <h3 className="mb-2 text-lg font-medium text-white">
              <Trans i18nKey={footer?.column2Title ?? "footer_column_2_title"} />
            </h3>
            <p className="mb-5 text-base font-light text-white">
              <Trans i18nKey={footer?.column2Description ?? "footer_column_2_description"} />
            </p>
            {footer?.column2phone1 && footer?.column2phone1 != "" && (
              <div className="flex flex-wrap gap-2">
                <img src="/images/icons/call.svg" alt="call" />
                <p className="text-base font-light text-white">
                  <Trans i18nKey={footer?.column2phone1Title ?? "footer_column_2_phone_1"} />
                </p>
                <a
                  className="relative text-base font-light text-white after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                  href={`tel:${footer?.column2phone1}`}>
                  {footer?.column2phone1}
                </a>
              </div>
            )}
            {footer?.column2phone2 && footer?.column2phone2 != "" && (
              <div className="flex flex-wrap gap-2">
                <img src="/images/icons/call.svg" alt="call" />
                <p className="text-base font-light text-white">
                  <Trans i18nKey={footer?.column2phone2Title ?? "footer_column_2_phone_2"} />
                </p>
                <a
                  className="relative text-base font-light text-brand-text-white after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-white after:transition-all after:duration-300 hover:after:w-full"
                  href={`tel:${footer?.column2phone2}`}>
                  {footer?.column2phone2}
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-row flex-wrap justify-center gap-x-6 gap-y-4 sm:gap-x-8">
          <Image
            className="order-0 object-contain"
            src={footer?.logo1 ?? "/images/footer-logo-swiss-re-foundation.png"}
            width="155"
            height="50"
            alt="Swiss Re Foundation"
            placeholder="blur"
            blurDataURL="/images/blur.png"
          />
          <Image
            className="order-2 object-contain sm:order-1"
            src={footer?.logo2 ?? "/images/footer-logo-sustainable.png"}
            width="128"
            height="110"
            alt="Sustainable Development Goals"
            placeholder="blur"
            blurDataURL="/images/blur.png"
          />
          <Image
            className="order-1 object-contain sm:order-2"
            src={footer?.logo3 ?? "/images/footer-logo-hipaa.png"}
            width="153"
            height="79"
            alt="Hipaa Compliance"
            placeholder="blur"
            blurDataURL="/images/blur.png"
          />
          <Image
            className="order-2 object-contain sm:order-3"
            src={footer?.logo4 ?? "/images/footer-logo-top-vendor.png"}
            width="97"
            height="110"
            alt="Top Vendor"
            placeholder="blur"
            blurDataURL="/images/blur.png"
          />
          <Image
            className="order-2 object-contain sm:order-4"
            src={
              footer?.logo5 && footer.logo5.length > 0
                ? footer.logo5 === "/images/footer-logo-aicpa-soc.png"
                  ? "/images/footer-logo-prescient-SOC2-Type-2-Badge.png"
                  : footer.logo5
                : "/images/footer-logo-prescient-SOC2-Type-2-Badge.png"
            }
            width="120"
            height="120"
            alt="AICPA SOC"
            placeholder="blur"
            blurDataURL="/images/blur.png"
          />
        </div>
        <div className="mx-auto flex w-full  max-w-[350px] flex-col items-center py-10 sm:max-w-none lg:pl-16 lg:pr-14">
          <p className="mb-4 text-center text-xs-custom text-brand-text-white sm:mb-6">
            <Trans i18nKey={footer?.bottomText1 ?? "footer_bottom_text_1"} />
          </p>
          <p className="mb-4 text-center text-xs-custom text-brand-text-white  sm:mb-6">
            <Trans i18nKey={footer?.bottomText2 ?? "footer_bottom_text_2"} />
          </p>
          <p className="mb-4 text-center text-xs-custom text-brand-text-white  sm:mb-6">
            <span className="mr-1">©</span>
            <span className="mr-1">{new Date().getFullYear()}</span>
            <Trans i18nKey={footer?.bottomText3 ?? "footer_bottom_text_3"} />
          </p>
          <p className="mb-4 text-center text-xs-custom text-brand-text-white sm:mb-6">
            <Trans i18nKey={footer?.bottomText4 ?? "footer_bottom_text_4"} />
          </p>
          <p className="text-center text-xs-custom text-brand-text-white">
            <Trans i18nKey={footer?.bottomText5 ?? "footer_bottom_text_5"} />
            <span className="mr-1">©</span>
            <span className="">{new Date().getFullYear()}</span>
          </p>
          <Link
            href={`mailto:${footer?.email ?? "support@simpletherapy.com"}`}
            className="relative w-fit text-center text-xs-custom text-brand-text-white after:absolute after:bottom-0 after:left-0 after:h-[1px] after:w-0 after:bg-white after:transition-all after:duration-300 hover:after:w-full">
            {footer?.email ?? "support@simpletherapy.com"}
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default AppFooter;
