import classNames from "classnames";
import React from "react";

export function CriticalErrorScreen({ className, ...rest }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames("flex min-h-screen flex-col items-center justify-center", className)}
      {...rest}>
      <h1>Oops! Something went wrong</h1>
      <p>We encountered an error while loading the page.</p>
      <button
        className="bg-brand-bg-main relative mt-10 mt-4 inline-flex min-h-[51px] w-full items-center justify-center gap-1 rounded-full bg-brand-orange-600 py-2 pl-14 pr-20 text-base font-light text-white transition-colors duration-300 hover:bg-brand-orange-300 sm:w-fit"
        onClick={() => window.location.reload()}
        style={{ marginRight: "10px", padding: "10px 20px", cursor: "pointer" }}>
        Try Again
      </button>
    </div>
  );
}
